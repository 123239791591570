<template>
  <div>
    <van-list v-model="listLoading" :finished="listFinished" @load="listLoad" finished-text="没有更多了">
      <van-cell-group v-for="(item,index) in materielList" :key="index" :title="item.materielCode">
        <van-cell title="来料时间" :value="item.materielTime"></van-cell>
        <van-cell title="地址" :value="item.address"></van-cell>
        <van-cell title="质量" :value="item.quality"></van-cell>
      </van-cell-group>
    </van-list>
  </div>
</template>

<script>
import Vue from "vue";
import Vant from "vant";

Vue.use(Vant);
export default {
  props: ["serialNumber"],
  data() {
    return {
      materielList: [],
      listLoading: false,
      listFinished: false,
      currentPage: 1,
    };
  },
  methods: {
    listLoad() {
      let that = this;
      that.axios
        .post("Materiel/GetListBySerialNumber", {
          currentPage: that.currentPage,
          onlyText: that.serialNumber,
        })
        .then(function (response) {
          response.data.data.data.forEach((element) => {
            that.materielList.push(element);
          });
          that.listLoading = false;
          if (that.materielList.length >= response.data.data.total) {
            that.listFinished = true;
          } else {
            that.currentPage++;
          }
        });
    },
  },
  mounted() {},
};
</script>

<style>
</style>